'use client';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { ProductSubscriptionType } from '@dr-pam/common-types/database';
import SubscriptionComparison from '@dr-pam/common-components/Components/Subscription/SubscriptionComparison';
import Button from '@dr-pam/common-components/Components/Form/Button';
import Link from 'next/link';
import { ModalProviderValue, useModalProvider } from '@dr-pam/common-components/Components/Modal/ModalProvider';
import { ModalProps } from '@dr-pam/common-components/Components/Modal/Modal';
import Cart, { CartItem, CartRef } from '@dr-pam/common-components/Components/Payment/Cart';
import SignupForm, { SignupFormType } from '@dr-pam/common-components/Components/Auth/SignupForm';
import { usePaymentService } from '@dr-pam/common-components/Services/PaymentService';
import { PurchaseSubscriptionResponse } from '@dr-pam/common-components/Models/Payment';
import SessionStorageUtil from '@dr-pam/common-components/Utils/SessionStorageUtil';
import { StripeElementsRef } from '@dr-pam/common-components/Components/Payment/StripeProvider';
import useLoadTracker from '@dr-pam/common-components/Hooks/useLoadTracker';
import { JsonApiError } from '@dr-pam/common-components/Utils/ErrorUtils';
import NotificationUtils from '@dr-pam/common-components/Utils/NotificationUtils';
import { UseFormReturnType } from '@mantine/form';
import StripePaymentForm from '@dr-pam/common-components/Components/Payment/StripePaymentForm';
import { IconLock } from '@tabler/icons-react';
import PoweredByStripe from '@dr-pam/common-components/Components/Payment/PoweredByStripe';
import useAuthenticatedUser from '@dr-pam/common-components/Hooks/useAuthenticatedUser';
import { useAuthProvider, useAuthService } from '@dr-pam/common-components/Components/Auth/AuthProvider';
import Checkbox from '@dr-pam/common-components/Components/Form/Checkbox';
import CouponUtils from '@dr-pam/common-components/Utils/CouponUtils';
import type { Coupon } from '@dr-pam/common-types/database';
import { useUserService } from '@dr-pam/common-components/Services/UserService';
import { useToastProvider } from '@dr-pam/common-components/Components/Toast/ToastProvider';

const SESSION_STORAGE_FORM = 'signup.form';

export type ModalComponent = {
	title: string;
	children: ReactNode;
};

export type PurchaseSubscriptionModalProps = {
	className?: string;
	modalId: string;
	subscriptionTypes: ProductSubscriptionType[];
	renewsSubscriptionId?: string;
	renderFirst?: (options: { goForward: () => void }) => ModalComponent;
};

export default function PurchaseSubscriptionModal(props: PurchaseSubscriptionModalProps) {
	const { className, modalId, subscriptionTypes, renewsSubscriptionId, renderFirst } = props;

	const elRef = useRef<HTMLDivElement>(null);
	const stripeRef = useRef<StripeElementsRef>(null);
	const signupFormRef = useRef<UseFormReturnType<SignupFormType>>(null);
	const cartRef = useRef<CartRef>(null);

	const modalProvider = useModalProvider();
	const authService = useAuthService();
	const paymentService = usePaymentService();
	const userService = useUserService();
	const authProvider = useAuthProvider();
	const toastProvider = useToastProvider();

	const { user } = useAuthenticatedUser();
	const { isLoading, addLoader, removeLoader } = useLoadTracker();

	const [currentIndex, setCurrentIndex] = useState(0);
	const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<ProductSubscriptionType | undefined>();

	const [signupFormValues, setSignupFormValues] = useState<SignupFormType | null>(null);
	const [paymentWithoutCoupon, setPaymentWithoutCoupon] = useState<PurchaseSubscriptionResponse | null>(null);
	const [paymentWithCoupon, setPaymentWithCoupon] = useState<PurchaseSubscriptionResponse | null>(null);
	const [coupon, setCoupon] = useState<Coupon | null>(null);
	const [couponCodeError, setCouponCodeError] = useState<string | null>(null);

	const [termsChecked, setTermsChecked] = useState(false);
	const [continueDisabled, setContinueDisabled] = useState(true);

	const goForward = useCallback(() => {
		setCurrentIndex((current) => current + 1);
	}, []);

	const goBack = useCallback(() => {
		setCurrentIndex((current) => current - 1);
	}, []);

	const handleCheckboxChange = (checked: boolean) => {
		setTermsChecked(checked);
		setContinueDisabled(!checked);
	};

	useEffect(() => {
		const formValues = SessionStorageUtil.getItemAndParse<SignupFormType>(SESSION_STORAGE_FORM);
		if (formValues) {
			signupFormRef?.current?.setValues(formValues);
			setSignupFormValues(formValues);
		}
	}, [currentIndex]);

	const payment = coupon ? paymentWithCoupon : paymentWithoutCoupon;

	const handleOnCouponRequested = useCallback(
		async (couponCode: string) => {
			if (!payment) {
				NotificationUtils.showError(new Error('No payment found'), 'Error');
				return;
			}
			if (!signupFormValues) {
				NotificationUtils.showError(new Error('No form values found'), 'Error');
				return;
			}
			if (!signupFormValues.billingAddress || !signupFormValues.billingCountryCode || !selectedSubscriptionType) {
				NotificationUtils.showError(new Error('Billing address and country code are required'), 'Error');
				return;
			}
			if (!selectedSubscriptionType.productId) {
				NotificationUtils.showError(new Error('Subscription type does not have a product ID'), 'Error');
				return;
			}

			try {
				setCouponCodeError(null);

				const request = paymentService.requestCoupon(couponCode, selectedSubscriptionType.productId);
				const coupon = await request.response;

				if (!paymentWithCoupon || paymentWithCoupon.payment.couponId != coupon.id) {
					const request = paymentService.createPayment({
						renewsSubscriptionId,
						productSubscriptionTypeId: selectedSubscriptionType.id,
						email: signupFormValues.email,
						billingAddress: signupFormValues.billingAddress,
						billingCountryCode: signupFormValues.billingCountryCode,
						couponId: coupon.id,
					});
					const payment = await request.response;
					setPaymentWithCoupon(payment);
				}

				setCoupon(coupon);
				NotificationUtils.showSuccess(
					`You saved ${CouponUtils.couponToString(coupon)}!`,
					'Successfully applied coupon',
				);

				setCoupon(coupon);
			} catch (err) {
				if (JsonApiError.isJsonApiError(err)) {
					if (err.statusCode === 404) {
						NotificationUtils.showError(new Error('This discount code appears to be invalid.'), 'Error');
						return;
					}
				}
				NotificationUtils.showError(err as Error, 'Failed to apply coupon');
				setCoupon(null);
			}
		},
		[payment, signupFormValues, selectedSubscriptionType, paymentService, paymentWithCoupon, renewsSubscriptionId],
	);

	const handleOnCouponRemoved = () => {
		setCoupon(null);
		setCouponCodeError(null);
		NotificationUtils.showSuccess(`Your discount have been removed.`, 'Successfully removed coupon');
	};

	const handleInitiatePaymentClicked = useCallback(
		async (values: SignupFormType) => {
			if (!values.billingAddress || !values.billingCountryCode || !selectedSubscriptionType) {
				return;
			}

			// If the user is signed in, check if they already have an active subscription to this product
			if (user) {
				const request = userService.getSubscriptions();
				const existingSubscriptions = await request.response;
				const existingSubscription = existingSubscriptions.find(
					(x) => x.productSubscriptionType.product?.id === selectedSubscriptionType.productId,
				);

				if (existingSubscription) {
					const toastId = toastProvider.showToast({
						color: 'error',
						title: 'Error',
						children: (
							<>
								You already have an account. Please visit{' '}
								<Link href="/account/billing" onClick={() => toastProvider.hideToast(toastId)}>
									your account
								</Link>{' '}
								to manage your subscription.
							</>
						),
					});
					return;
				}
			}

			setSignupFormValues(values);
			SessionStorageUtil.setItem(SESSION_STORAGE_FORM, JSON.stringify(values));

			try {
				const request = paymentService.createPayment({
					renewsSubscriptionId,
					productSubscriptionTypeId: selectedSubscriptionType.id,
					email: values.email,
					billingAddress: values.billingAddress,
					billingCountryCode: values.billingCountryCode,
					createUser: user
						? undefined
						: {
								fullName: values.fullName,
								password: values.password,
								healthDiscipline: null,
								regulatoryBody: null,
								registrationNumber: null,
								howDidYouHear: null,
						  },
				});
				const payment = await request.response;

				// Now authenticate the user using the email and password in the form
				const authenticatedUser = await authService.signIn(values.email, values.password);
				authProvider.updateUser(authenticatedUser);

				setPaymentWithoutCoupon(payment);
				setCurrentIndex((current) => current + 1);
			} catch (err) {
				// If the user already exists, try authenticating using the email and password in the form (only if we haven't tried this already)
				if (
					!user &&
					JsonApiError.isJsonApiError(err) &&
					(err.error === 'Conflict' || err.error === 'ConflictException')
				) {
					const authenticatedUser = await authService.signIn(values.email, values.password);
					if (authenticatedUser) {
						// Getting here means the existing email/password is correct, and we should try submitting again, this time using the authenticated user
						// (Cookie will now be set and the above request will be authenticated)
						try {
							// Update the user in the auth provider, since it gets checked when we re-call handleInitiatePaymentClicked
							authProvider.updateUser(authenticatedUser);
							await handleInitiatePaymentClicked(values);
						} finally {
							// We don't want to fall through to the original error, so return here
							return;
						}
					} else {
						// Invalid credentials
						// Do nothing and fall through to the original error
					}
				}

				NotificationUtils.showError(err as Error, 'Error');
			}
		},
		[
			authProvider,
			authService,
			paymentService,
			renewsSubscriptionId,
			selectedSubscriptionType,
			toastProvider,
			user,
			userService,
		],
	);

	const cartItems = useMemo<CartItem[]>(() => {
		if (!selectedSubscriptionType) {
			return [];
		}

		const cartItems: CartItem[] = [
			{
				description: (
					<p>
						Possums Sleep Program
						<br />
						<span className="font-sm">
							{selectedSubscriptionType.name} ({selectedSubscriptionType.durationInDays} days)
						</span>
					</p>
				),
				priceInCents: selectedSubscriptionType.priceInCents,
			},
		];

		return cartItems;
	}, [selectedSubscriptionType]);

	const handlePaymentCancelled = useCallback(() => {
		setPaymentWithCoupon(null);
		setPaymentWithoutCoupon(null);
		setCoupon(null);
		goBack();
	}, [goBack]);

	const handlePaymentSubmitted = useCallback(async () => {
		if (!signupFormValues || !signupFormValues.billingAddress) {
			return;
		}

		const loader = addLoader();

		try {
			if (cartRef.current?.hasUnappliedCoupon()) {
				const error =
					'Please click the "apply" button or remove the discount code before proceeding with payment.';
				setCouponCodeError(error);
				throw new Error(error);
			}

			if (payment?.stripeClientSecret && stripeRef.current?.stripe && stripeRef.current.elements) {
				const { error: err } = await stripeRef.current.elements.submit();
				if (err) {
					throw err;
				}
				// If the user is not signed in, sign them in using the credentials in the form
				if (!user && signupFormValues.email && signupFormValues.password) {
					await authService.signIn(signupFormValues.email, signupFormValues.password);
				}
				const { error } = await stripeRef.current.stripe.confirmPayment({
					clientSecret: payment.stripeClientSecret,
					elements: stripeRef.current.elements,
					confirmParams: {
						return_url: `${window.location.origin}/sign-up/complete`,
						payment_method_data: {
							billing_details: {
								name: signupFormValues.fullName,
								email: signupFormValues.email,
								phone: '',
								address: {
									line1: signupFormValues.billingAddress.line1 ?? '',
									line2: signupFormValues.billingAddress.line2 ?? '',
									city: signupFormValues.billingAddress.city ?? '',
									state: signupFormValues.billingAddress.state ?? '',
									postal_code: signupFormValues.billingAddress.postalCode ?? '',
									country: signupFormValues.billingCountryCode ?? 'AU',
								},
							},
						},
					},
				});
				if (error) {
					throw error;
				} else {
					SessionStorageUtil.removeItem(SESSION_STORAGE_FORM);
				}
			} else {
				throw new Error('Stripe did not load!');
			}
			removeLoader(loader);
		} catch (err) {
			NotificationUtils.showError(err as Error, 'Payment failed', 0);
		} finally {
			removeLoader(loader);
		}
	}, [addLoader, authService, payment?.stripeClientSecret, removeLoader, signupFormValues, user]);

	const components = useMemo(() => {
		const components: ModalComponent[] = [
			{
				title: 'Choose your access',
				children: (
					<SubscriptionComparison
						key={1}
						items={subscriptionTypes}
						selectedItem={selectedSubscriptionType}
						onItemSelected={setSelectedSubscriptionType}
					>
						{selectedSubscriptionType ? (
							<div className="continue-with-selection">
								<label>
									<Checkbox value={termsChecked} onChange={handleCheckboxChange} />
									<p className="font-sm tal">
										I have read and agree to the{' '}
										<Link href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
											terms and conditions.
										</Link>{' '}
										I understand that access plans renew automatically. I understand that in the
										event of cancellation my plan will no longer renew, I will retain access to the
										program for the remainder of my current access period, and that cancellation
										will not result in a refund.
									</p>
								</label>
								<div>
									<Button
										className="primary large-button"
										onClick={goForward}
										disabled={continueDisabled}
									>
										Continue
									</Button>
								</div>
							</div>
						) : null}
					</SubscriptionComparison>
				),
			},
			{
				title: user ? 'Confirm your details' : 'Create your account',
				children: (
					<div className="checkout-details">
						<p>
							You&apos;re nearly there! Please{' '}
							{user ? 'verify your billing details' : 'create an account'} to purchase
							<br />
							<strong>Possums Sleep Program - {selectedSubscriptionType?.name}</strong>
						</p>
						<SignupForm
							ref={signupFormRef}
							onSubmit={handleInitiatePaymentClicked}
							submitButtonText="Continue to payment"
							additionalButtons={
								<Button className="subtle" onClick={goBack}>
									Back
								</Button>
							}
							includeBillingAddress
							showLabels
							prefillUser={user || undefined}
						/>
					</div>
				),
			},
			{
				title: 'Checkout',
				children: payment ? (
					<div className="payment-details">
						<Cart
							ref={cartRef}
							items={cartItems}
							inputError={couponCodeError ?? undefined}
							coupon={coupon ?? undefined}
							onCouponRequested={handleOnCouponRequested}
							onCouponRemoved={handleOnCouponRemoved}
						/>
						{signupFormValues?.billingCountryCode === 'AU' ? (
							<p className="gst">The above prices are inclusive of GST.</p>
						) : null}
						<StripePaymentForm clientSecret={payment.stripeClientSecret} ref={stripeRef} color="#406e8e" />
						<div className="buttons">
							<PoweredByStripe />
							<Button className="subtle" disabled={isLoading} onClick={handlePaymentCancelled}>
								Back
							</Button>
							<Button
								onClick={handlePaymentSubmitted}
								isLoading={isLoading}
								className="flex gsm ac pay-now"
							>
								<IconLock />
								<span>Pay now</span>
							</Button>
						</div>
					</div>
				) : null,
			},
		];

		if (renderFirst) {
			components.unshift(renderFirst({ goForward }));
		}

		return components;
	}, [
		subscriptionTypes,
		selectedSubscriptionType,
		termsChecked,
		goForward,
		continueDisabled,
		user,
		handleInitiatePaymentClicked,
		goBack,
		payment,
		cartItems,
		couponCodeError,
		coupon,
		handleOnCouponRequested,
		signupFormValues?.billingCountryCode,
		isLoading,
		handlePaymentCancelled,
		handlePaymentSubmitted,
		renderFirst,
	]);

	const componentToRender = components[currentIndex];

	useEffect(() => {
		const scrollEl = elRef.current?.closest('.body');
		if (scrollEl) {
			scrollEl.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [currentIndex]);

	useEffect(() => {
		modalProvider.setModalTitle(modalId, componentToRender.title);
	}, [modalProvider, modalId, componentToRender.title]);

	return (
		<div className={`PurchaseSubscriptionModal ${className ?? ''}`} ref={elRef}>
			{componentToRender.children}
		</div>
	);
}

export function showPurchaseSubscriptionModal(
	modalProvider: ModalProviderValue,
	props: PurchaseSubscriptionModalProps,
	modalProps: Partial<ModalProps> = {},
) {
	modalProvider.openModal({
		...modalProps,
		modalId: props.modalId,
		className: 'blur popup-overlay',
		children: <PurchaseSubscriptionModal {...props} />,
	});
}
